:root {
  --color-primary: rgb(0, 0, 0);
  --color-secondary: rgb(255, 255, 255);
  --color-text: rgb(196, 196, 196);
  --color-bg: rgb(13, 17, 23);
  --color-bg-2: rgb(36, 41, 47);
  --responsive-padding: 20px;
}

* {
  box-sizing: border-box;
  line-height: 1.5;
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: var(--color-bg);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/** Style the scrollbars */
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: var(--color-bg-2);
}

::-webkit-scrollbar-thumb {
  background: var(--color-secondary);
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-secondary);
}

/* Path: src\index.css */