.home {
  margin: 0 auto;
}

.home-banner {
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0.5) 51.56%,
      rgba(0, 0, 0, 0.9) 100%
    ),
    url("../../assets/images/devfest-rift-valley-banner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right 0px center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  height: 200px;
  width: 100%;
}

.home-intro {
  max-width: 1250px;
  margin: 0 auto;
  margin-top: -125px;
  padding: 20px;
}

.home-intro-date {
  color: var(--color-secondary);
  font-size: 20px;
  font-weight: 500;
}

.home-intro-title {
  color: var(--color-secondary);
  font-size: 5.5rem;
  font-weight: 700;
  margin: 5px 0;
  margin-left: -5px;
}

.home-intro-description {
  color: var(--color-secondary);
  font-size: 1.5rem;
  font-weight: 400;
}

.home-schedule {
  max-width: 1250px;
  margin: 0 auto;
  padding: 20px;
}

.home-tab-nav {
  background-color: var(--color-bg-2);
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 0 10px;
  border-radius: 8px;
}

.home-tab-nav-item {
  color: var(--color-secondary);
  font-size: 1rem;
  font-weight: 500;
  padding: 15px 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.home-tab-nav-item:hover {
  border-bottom: 2px solid var(--color-secondary);
}

.home-tab-nav-item:first-child {
  border-bottom: 2px solid var(--color-secondary);
}

.home-tab-nav-item:nth-child(2) {
  opacity: 0.5;
}

.home-tab-content {
  margin: 0 auto;
  padding: 20px 0;
}

.home-tab-content-item,
.home-tab-content-item-btns {
  display: flex;
  align-items: center;
  gap: 10px;
}

.home-tab-content-item {
  justify-content: space-between;
}

.home-tab-content-item-btns {
  justify-content: flex-end;
}

.home-tab-content-item-btn {
  cursor: pointer;
  outline: 0;
  transition: all 0.2s ease-in-out;
  border: 1px solid var(--color-secondary);
  background-color: var(--color-bg-2);
  color: var(--color-secondary);
  border-radius: 5px;
  padding: 8px 15px;
}

.home-tab-content-item-btn:hover {
  background-color: var(--color-secondary);
  color: var(--color-primary);
}

.home-tab-content-item-title,
.home-tab-content-item-description {
  color: var(--color-secondary);
  font-size: 0.9rem;
  font-weight: 500;
  margin: 5px 0;
}

.home-tab-content-item-title {
  color: var(--color-text);
  font-weight: 700;
  font-size: 1.2rem;
}

.schedule {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1250px;
  margin: 0 auto;
  padding: 20px 0;
}

.schedule-track-sessions {
  display: flex;
  gap: 20px;
}

.schedule-track-title {
  color: var(--color-secondary);
  font-size: 1.1rem;
  font-weight: 700;
  margin: 0;
  margin-bottom: 15px;
}

.schedule-item {
  display: flex;
  gap: 10px;
  padding: 20px;
  color: var(--color-secondary);
  background-color: var(--color-bg-2);
  border-radius: 8px;
  flex: 1;
}

.schedule-item-time {
  font-size: 1.2rem;
  font-weight: 500;
  width: 125px;
}

.schedule-info {
  flex: 1;
}

.schedule-info-title {
  font-size: 1.2rem;
  font-weight: 500;
}

.schedule-info-description {
  margin: 0;
  margin-top: 10px;
  color: var(--color-text);
}

.schedule-info-speakers {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin: 0;
  margin-top: 10px;
  color: var(--color-text);
}

.schedule-info-speaker {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
  color: var(--color-text);
}

.schedule-info-speaker-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--color-secondary);
  object-fit: cover;
}

.schedule-info-speaker-role {
  font-size: 0.8rem;
  font-weight: 400;
  color: var(--color-secondary);
}

.home-footer {
  max-width: 1250px;
  margin: 0 auto;
  padding: 20px;
  color: var(--color-text);
  border-top: 1px solid var(--color-bg-2);
}

.home-footer-links {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 0;
  padding: 0;
}

.home-footer-links a {
  color: var(--color-text);
  font-size: 0.8rem;
  font-weight: 500;
  text-decoration: none;
}

.home-footer-links a:hover {
  text-decoration: underline;
}

/** Media Queries */
@media (max-width: 768px) {
  .home-banner {
    background-position: center;
    height: 125px;
  }

  .home-intro {
    margin-top: -80px;
  }

  .home-intro-title {
    font-size: 1.8rem;
    margin-left: 0;
  }

  .home-intro-description {
    font-size: 1rem;
  }

  .home-tab-nav-item {
    padding: 15px 20px;
    text-align: center;
  }

  .home-tab-content-item,
  .schedule-item,
  .schedule-track-sessions {
    flex-direction: column;
    align-items: flex-start;
  }

  .home-tab-content-item-btns {
    justify-content: center;
  }
}
