.header {
  color: var(--color-secondary);
  display: flex;
  background: var(--color-primary);
  min-height: 72px;
  padding: 0 var(--responsive-padding);
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
}

.header-ctas {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 10px;
  text-transform: uppercase;
}

.header-cta {
  background-color: var(--color-primary);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: var(--color-secondary);
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 5px;
  box-shadow: rgb(255 255 255 / 25%) 0px 0px 0px 1px inset !important;
  transition: all 0.2s ease-in-out;
}

.header-cta:hover {
  background-color: var(--color-primary);
  color: var(--color-secondary);
  border: 1px solid var(--color-secondary);
}

.header-logo {
  max-width: 175px;
  width: 100%;
}
